<script>

</script>

<section id="about-vocabeo">
    <p>
        <span class="vocabeo">vocabeo</span> is designed to help you easily build up
        your <span class="emph-word">German vocabulary</span> and keep it for the long term. As a combination of <span
            class="emph-word">Mini Dictionary</span> and
        a <span class="emph-word">Vocabulary Trainer</span> it allows you to
    </p>
    <ol>
        <li>
            <a href="/browse">
                <div>
                    <span class="emph">Browse</span> the 5700+ most common German words
                </div>
            </a>
        </li>
        <li>
            <a href="/browse">
                <div>
                    <span class="emph">Filter</span> words by word class & level (&hairsp;A1&hairsp;/&hairsp;A2&hairsp;/&hairsp;B1&hairsp;)
                </div>
            </a>
        </li>
        <li>
            <a href="/browse">
                <div>
                    <span class="emph">Decide</span> exactly which words you'd like to learn
                </div>
            </a>
        </li>
        <li>
            <a href="/browse">
                <div>
                    Start with the <span class="emph">most frequent</span> words
                </div>
            </a>
        </li>
        <li>
            <a href="/learn">
                <div>
                    <span class="emph">Learn</span> in context with example sentences
                </div>
            </a>
        </li>
        <li>
            <a href="/learn">
                <div>
                    <span class="emph">Bulk add</span> multiple words based on different filters
                </div>
            </a>
        </li>
        <li>
            <a href="/learn">
                <div>
                    Remember easily with <span class="emph">images & audio</span>
                </div>
            </a>
        </li>
        <li>
            <a href="/learn">
                <div>
                    Learn effectively with <span class="emph">spaced repetition</span>
                </div>
            </a>
        </li>
        <li>
            <a href="/signin">
                <div>
                    View <span class="emph">statistics</span> on saved & mastered words
                </div>
            </a>
        </li>
    </ol>
</section>

<style>
    section {
        margin-bottom: 8rem;
    }

    p {
        margin-bottom: 2rem;
    }

    a {
        color: inherit;
    }

    .vocabeo,
    .emph-word {
        font-size: 1.1rem;
    }

    .vocabeo {
        font-weight: var(--font-extra-bold);
    }

    .emph-word {
        font-weight: var(--font-bold);
        /*color: #336280;*/
    }

    .emph {
        font-weight: var(--font-extra-bold);
        font-size: 1.1rem;
    }

    ol {
        --size: 2.4rem;
        --size-plus-gap: 2rem;
        display: flex;
        flex-wrap: wrap;
        /*grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));*/
        gap: 2.6rem 2rem;
        padding: 1rem 0 0 0;
        list-style: none;
    }

    li {
        position: relative;
        flex-grow: 1;
        padding: 2.4rem 1rem;
        line-height: 1.5;
        text-align: center;
        /*transform-style: preserve-3d;*/
        border-radius: var(--border-radius);
        font-size: .95rem;
        background: var(--color-light);
    }

    li div {
        margin: auto;
        max-width: 21ch;
    }

    li::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        /*transform: translateZ(-10px);*/
        width: 1.6rem;
        height: 1.6rem;
        border-radius: var(--border-radius);
        /*filter: saturate(1.3);*/
    }

    li:nth-of-type(1)::before {
        background: var(--color-Conjunction);
    }

    li:nth-of-type(2)::before {
        background: var(--color-Adjective);
    }

    li:nth-of-type(3)::before {
        background: var(--color-Verb);
    }

    li:nth-of-type(4)::before {
        background: var(--color-Adverb);
    }

    li:nth-of-type(5)::before {
        background: var(--color-Preposition);
    }

    li:nth-of-type(6)::before {
        background: var(--color-Num);
    }

    li:nth-of-type(7)::before {
        background: var(--color-Pronoun);
    }

    li:nth-of-type(8)::before {
        background: var(--color-Article);
    }

    li:nth-of-type(9)::before {
        background: var(--color-Noun);
    }


    @media (max-width: 1400px) {
        ol {
            gap: 2rem;
        }
    }


    @media (max-width: 1000px) {

        section {
            margin-bottom: 5rem;
        }

        p {
            font-size: .87rem;
            margin-bottom: 1.2rem;
        }

        .vocabeo,
        .emph-word {
            font-size: .96rem;
        }

        ol {
            gap: 1.5rem;
            padding: .5rem 0 0 0;
        }

        li {
            padding: 1.4rem 2rem;
            font-size: .9rem;
        }

        .emph {
            font-size: 1rem;
        }

        li::before {
            width: 1.2rem;
            height: 1.2rem
        }

    }


</style>