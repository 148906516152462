<script>
    function sendMessage() {
        try {
            window.Tawk_API.maximize()
        } catch (error) {
            console.error('Tawk_API couldn`t be opened', error)

            const link = document.createElement('a')
            const l1 = '?subject=Feedback'
            const l2 = 'corinna'
            const l3 = '@vocabeo.com'
            const l4 = 'mailto:'
            link.href = l4 + l2 + l3 + l1
            link.click()
        }
    }
</script>

<section>
    <h2>
        What do you think?
    </h2>

    <p>
        Do you enjoy using <b>vocabeo</b>? Awesome! We appreciate your support and would be thrilled if you could help
        us spread the word to others who might benefit from our product. Writing a review, posting on social media, or
        simply telling your friends can make a big difference in helping us reach more language learners. On the other
        hand, if you have any feedback or suggestions for improvement, we are all ears. We believe that feedback is
        crucial to our success and we welcome any comments or suggestions that you may have.
    </p>

    <ul>
        <li>
            <h3>
                Spread the word
            </h3>
            <p>
                Tell the world about us and help the <b>vocabeo</b> community grow
            </p>
            <p style="font-size: 1.9rem; margin: 0; line-height: 1;">
                💬
            </p>
        </li>
        <li>
            <h3>
                Share your feedback
            </h3>
            <p>
                What could be improved? Which features would you like to see in the future?
            </p>
            <button on:click={sendMessage}>
                Send a message
            </button>
        </li>
    </ul>
</section>

<style>
    ul {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        list-style: none;
        padding: 0;
    }

    li {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        flex-basis: 30ch;
        flex-grow: 1;
        padding: 1rem 0;
        text-align: center;
        /*border: 1px solid lightgrey;*/
    }

    li p {
        max-width: 33ch;
    }

    h3 {
        font-size: 1.1rem;
    }

    p {
        margin: 0 0 2rem 0;
    }

    button {
        padding: 1rem 1.4rem;
        border-radius: var(--border-radius);
        color: white;
        font-weight: var(--font-bold);
    }

    li:nth-of-type(2) button {
        background: var(--color-Adjective);
    }

</style>